import * as React from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useProject, useMap } from 'Client/utils/hooks';
import { useUser } from 'Client/utils/hooks/useUser';
import { getUserAgreements } from 'Client/services/map';
import { ContributionType } from 'Shared/types/contribution';
import { ContributionFlowPanel } from 'Molecules/ContributionFlowPanel';
import { ContributionHighlight } from 'Molecules/ContributionHighlight';
import { CustomLayerHighlight } from 'Molecules/CustomLayerHighlight';
import { ContributionPanel } from 'Molecules/ContributionPanel';
import { CustomLayerPanel } from 'Molecules/CustomLayerPanel';
import { Image3DView } from 'Molecules/Image3DView';
import { MapActionButtons } from 'Molecules/MapActionButtons';
import { MapModeSwitch } from 'Molecules/MapModeSwitch';
import { MapZoomButton } from 'Molecules/MapZoomButton';
import { MapLegends } from 'Molecules/MapLegends';
import { MapInfoPanel } from 'Molecules/MapInfoPanel';
import { Helper3D } from 'Molecules/Helper3D';
import { HubPagesTemplate as Template } from 'Templates';
import { getContributionUserId } from 'Client/services/contributions';
import {
  getLocalItem,
  CONTRIBUTION_SESSION_ITEM,
} from 'Client/utils/localstorage';
import {
  MapBoxSearchBoxRetrieveApiResponse,
  MapProjection,
  Xyz,
} from 'Shared/types/map';
import { MapSearchField } from 'Organisms';
import { AdjustIcon, SearchIcon } from 'Atoms/Icons';
import { PanelTabIndex } from 'Client/components/molecules/MapInfoPanel/types';
import { useBanner } from 'Client/utils/hooks/useBanner';
import { ContributionFlowPanelCf3 } from 'Client/components/molecules/ContributionFlowPanelCf3/ContributionFlowPanelCf3';
import { MapPageProps } from './types';
import {
  SearchInputContainer,
  SearchInputIcon,
  MapLeftPanel,
  FilterButtonSection,
  SearchInputWrapper,
} from './MapPage.styles';
import { withDraftProtection } from '../proposals/ProposalPage';
import { onPointerMoveV3, onPointerMoveV4 } from './utils/onPointerMove';
import { onClickV4 } from './utils/onClick';

const GeolytixMap = dynamic(() => import('Client/components/map/GeolytixMap'), {
  ssr: false,
});

const MapPage: React.FC<MapPageProps> = ({
  pageId,
  slug,
  stage,
  type,
  card,
  createdAt,
  sentimentQuestion,
  questions,
  geolytixWorkspace,
  openGraphInfo,
  infoPanel,
  featureFlags,
  ...props
}) => {
  const {
    dispatch,
    state: {
      image3d,
      selectedContribution,
      selectedCustomLayer,
      highlightedContribution,
      contributionFlow,
      welcomePanel,
      highlightedCustomLayer,
      activeInfoPanelTab,
      infoPanelOpen,
      mode,
      isContributionFlowStarted,
      ...state
    },
    version,
  } = useMap();
  const xyz = state.xyz as Xyz;
  const project = useProject();
  const { user } = useUser();
  const router = useRouter();
  const { t } = useTranslation();
  const [centroid, setCentroid] = React.useState({ long: 0, lat: 0 });
  const isLeftPanelOpen =
    !!welcomePanel ||
    !!infoPanelOpen ||
    !!contributionFlow ||
    !!selectedContribution ||
    !!selectedCustomLayer;

  const isEmbed = router?.query?.embed === 'true';

  const proposal: MapPageProps = React.useMemo(() => {
    return {
      pageId,
      slug,
      stage,
      type,
      card,
      createdAt,
      sentimentQuestion,
      questions,
      geolytixWorkspace,
      infoPanel,
      featureFlags,
    };
  }, [
    card,
    createdAt,
    featureFlags,
    geolytixWorkspace,
    infoPanel,
    pageId,
    questions,
    sentimentQuestion,
    slug,
    stage,
    type,
  ]);

  React.useEffect(() => {
    const long = proposal.geolytixWorkspace.locales['UK'].view.lng;
    const { lat } = proposal.geolytixWorkspace.locales['UK'].view;

    setCentroid({
      long,
      lat,
    });
  }, [proposal.geolytixWorkspace.locales]);

  const hideWelcomePanelFlag = proposal?.featureFlags?.hideWelcomePanel;
  const hideProjectInfoTab = proposal?.featureFlags?.hideProjectInfoTab;

  const {
    welcomePanel: welcomePanelUrl,
    hideWelcomePanel: hideWelcomePanelUrl,
  } = router.query;

  const hideWelcomePanel =
    !!hideWelcomePanelFlag || !welcomePanel || hideWelcomePanelUrl === 'true';

  if (hideWelcomePanelFlag && welcomePanel) {
    dispatch({ type: 'CLOSE_WELCOME_PANEL' });
  }

  React.useEffect(() => {
    if (!user) return;
    getUserAgreements().then((agreements) => {
      dispatch({
        type: 'SET_USER_AGREEMENTS',
        payload: { userAgreements: agreements },
      });
    });
  }, [user, dispatch]);

  React.useEffect(() => {
    // Normally `onInit()` is called on first load of the geolytix map data. Now
    // if a user updates locale while in map view or contribution flow, we must
    // reinitialize proposal context (but only once xyz already exists).
    xyz && onInit(xyz);
  }, [questions, sentimentQuestion]);

  React.useEffect(() => {
    // OpenLayers Event Listeners for XYZ v3:
    if (xyz && version.includes('v3') && proposal) {
      onPointerMoveV3({
        xyz,
        dispatch,
      });
    }

    // OpenLayers Event Listeners for XYZ v4:
    if (xyz && version.includes('v4') && proposal) {
      onClickV4({
        xyz,
        dispatch,
        page: proposal,
        project,
        isNavigationalMap: false,
      });

      onPointerMoveV4({
        xyz,
        dispatch,
        pageId: proposal.pageId,
        projectId: project._id,
      });
    }
  }, [xyz]);

  const onInit = React.useCallback(
    (xyz?: Xyz) => {
      const proposalSlug = router.query.slug[0];

      const showWelcomePanel = welcomePanel || welcomePanelUrl;

      const welcomePanelLocalStorage = JSON.parse(
        window.localStorage.getItem(`welcomePanel-${proposalSlug}`)
      );

      if (hideWelcomePanel) {
        dispatch({ type: 'CLOSE_WELCOME_PANEL' });
        dispatch({
          type: 'OPEN_INFO_PANEL',
          payload: {
            infoPanelOpen: true,
            activeInfoPanelTab: hideProjectInfoTab
              ? PanelTabIndex.FILTERS_TAB
              : PanelTabIndex.INFO_TAB,
          },
        });
      } else if (welcomePanelLocalStorage && !showWelcomePanel) {
        const { dismissed } = welcomePanelLocalStorage;
        if (dismissed) {
          dispatch({ type: 'CLOSE_WELCOME_PANEL' });
        }
      } else {
        dispatch({ type: 'CLOSE_WELCOME_PANEL' });
        dispatch({
          type: 'OPEN_INFO_PANEL',
          payload: {
            infoPanelOpen: true,
            activeInfoPanelTab,
          },
        });
      }

      const cid = router?.query?.cid as string | null;

      if (cid) {
        dispatch({
          type: 'FOCUS_CONTRIBUTION',
          payload: { cid, dispatch },
        });
      }

      dispatch({
        type: 'INITIALIZE_LAYERS',
        payload: {
          xyz,
          dispatch,
          page: proposal,
          project,
          uniqueId: getContributionUserId({
            user,
            storedUserId: getLocalItem(CONTRIBUTION_SESSION_ITEM),
          }),
          lang: router.locale,
        },
      });
    },
    [
      activeInfoPanelTab,
      dispatch,
      hideProjectInfoTab,
      hideWelcomePanel,
      project,
      proposal,
      router.locale,
      router.query?.cid,
      router.query.slug,
      user,
      welcomePanel,
      welcomePanelUrl,
    ]
  );

  const handleFilterClick = () => {
    dispatch({
      type: 'OPEN_INFO_PANEL',
      payload: {
        infoPanelOpen: true,
        activeInfoPanelTab: PanelTabIndex.FILTERS_TAB,
      },
    });
  };

  const handleSearchSelection = async (option) => {
    if (!option) return;

    const maxZoom = xyz?.locale?.maxZoom;
    const searchZoom = xyz?.locale?.searchZoom || maxZoom - 2 || 16;
    const mapboxId = option.id;
    const sessionToken = localStorage.getItem('mapSessionToken');

    const response: Response = await fetch(`/api/map/fetchCoordinates`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        mapboxId,
        sessionToken,
      }),
    });

    const result: MapBoxSearchBoxRetrieveApiResponse = await response.json();

    const { coordinates } = result.features[0].geometry;

    const extent = global.ol.extent.boundingExtent([coordinates]);
    const boundingExtent = global.ol.proj.transformExtent(
      extent,
      global.ol.proj.get(MapProjection.WORLD_GEODETIC_GPS),
      global.ol.proj.get(MapProjection.WEB_MERCATOR)
    );
    const dpi = window.devicePixelRatio || 1;
    xyz.mapview.flyToBounds(boundingExtent, {
      padddings: [10, 10, 10, 10].map((padding) => padding * dpi),
      maxZoom: searchZoom,
    });
  };

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('xs'));
  const flagIsOn = proposal?.featureFlags?.showAddressSearchField;
  const isMobileAndPanelClosed =
    isMobile && !welcomePanel && !contributionFlow && !selectedContribution;

  const showSearch = flagIsOn && (!isMobile || isMobileAndPanelClosed);

  const displayContributionHighlight =
    highlightedContribution &&
    highlightedContribution.position &&
    !isContributionFlowStarted;

  const displayCustomLayerHighlight =
    highlightedCustomLayer &&
    highlightedCustomLayer.position &&
    highlightedCustomLayer?.hoverablePopup &&
    !isContributionFlowStarted;

  const { errorBannerHeight, stageBannerHeight } = useBanner();

  const handleCfRendering = () => {
    if (project.features.cf3) {
      return (
        <ContributionFlowPanelCf3
          {...props.cf3Props}
          sentimentQuestionId={proposal?.sentimentQuestion?.question || '0'}
          isMap
        />
      );
    } else {
      return <ContributionFlowPanel />;
    }
  };

  return (
    <Template
      showFooter={false}
      showProjectNavigation={false}
      title={card?.title}
      stage={stage}
      openGraphInfo={openGraphInfo}
      showProposalMasthead
      showProjectMasthead={false}
      slug={slug}
      isMap
    >
      <>
        <MapLeftPanel
          data-id="map-left-panel"
          isEmbed={isEmbed}
          errorBannerHeight={errorBannerHeight}
          stageBannerHeight={stageBannerHeight}
          cf3={project?.features?.cf3 || false}
        >
          {selectedContribution && <ContributionPanel />}
          {selectedCustomLayer && <CustomLayerPanel />}
          {displayContributionHighlight && <ContributionHighlight />}
          {displayCustomLayerHighlight && <CustomLayerHighlight />}
          {contributionFlow &&
            contributionFlow.type === ContributionType.COMMENT &&
            handleCfRendering()}
          {!selectedContribution && (
            <MapInfoPanel isWelcome={!hideWelcomePanel} proposal={proposal} />
          )}
        </MapLeftPanel>
        {showSearch && (
          <SearchInputContainer
            isOpen={isLeftPanelOpen}
            isMobile={isMobile}
            isEmbed={isEmbed}
            bannerHeight={errorBannerHeight + stageBannerHeight}
            cf3={project?.features?.cf3 || false}
            contributionFlowStarted={
              isContributionFlowStarted && !!contributionFlow
            }
          >
            <SearchInputWrapper>
              <MapSearchField
                isMobile={isMobile}
                name="search-address"
                handleChange={handleSearchSelection}
                centroid={`${centroid.long},${centroid.lat}`}
              />

              <SearchInputIcon htmlFor="search-address" isMobile={isMobile}>
                <SearchIcon width={20} height={20} color="#999999" />
              </SearchInputIcon>

              <FilterButtonSection
                onClick={handleFilterClick}
                isMobile={isMobile}
              >
                <AdjustIcon />
                <p>{t('Filter')}</p>
              </FilterButtonSection>
            </SearchInputWrapper>
          </SearchInputContainer>
        )}
        <MapActionButtons />
        <GeolytixMap onInit={onInit} pageId={pageId} isPositionFixed={true} />
        <MapModeSwitch />
        {mode === '3d' && !image3d && <Helper3D />}
        {image3d && <Image3DView />}
      </>
      {!image3d && (
        <>
          <MapZoomButton />
          <MapLegends isLeftPanelOpen={isLeftPanelOpen} />
        </>
      )}
    </Template>
  );
};

const wrapped = withDraftProtection(MapPage);
export { wrapped as MapPage };
