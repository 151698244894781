import * as React from 'react';
import { captureException } from '@sentry/node';
import { useRouter } from 'next/router';
import {
  useUser,
  MixpanelEventTypes,
  useAnalytics,
  useProposalContext,
  useProject,
} from 'Client/utils/hooks';
import { EngagementPagesTemplate } from 'Templates/EngagementPages';
import { EditModeButton } from 'Pages/edit/components/EditModeButton';
import { saveConsents as saveConsentsService } from 'Client/services/consent';
import { CONSENT_TYPES } from 'Client/constants/consents';
import { useUtils } from 'Client/utils/hooks/useUtils';
import { ConsentsContainer, Heading } from './components';
import { PreferencesPageProps, Consent } from './types';
import { Wrapper, IntroDescription } from './PreferencesPage.styles';

export const PreferencesPage: React.FC<PreferencesPageProps> = ({
  heading,
  description,
  defaultConsents,
  customConsents,
}) => {
  const { user } = useUser();
  const { apiToken } = useUtils();
  const project = useProject();
  const { locale, asPath } = useRouter();
  const { trackEvent } = useAnalytics();
  const [state] = useProposalContext();
  const [consents, setConsents] = React.useState<Array<Consent>>([]);
  const getEmailForConsent = (): string => {
    try {
      if (user) {
        return user.email;
      }
      //logic to logout user
      if (state && state.signupEmail) {
        const { signupEmail } = state;
        return signupEmail;
      }
      throw new Error('could not get email for user');
    } catch (error) {
      captureException(
        `error in getEmailForConsent @ PreferencesPage.tsx : ${error}`
      );
    }
  };

  const toggleConsents = (consent: Consent): void => {
    const { enabled } = consent;
    if (enabled) {
      const newConsents = [...consents, consent];
      setConsents(newConsents);
      return;
    }
    const newConsents = consents.filter((c) => c.heading !== consent.heading);
    setConsents(newConsents);
  };

  const saveConsents = async () => {
    trackEvent(MixpanelEventTypes.ADDED_PREFERENCES, {
      path: asPath,
      consents: consents?.map((c) => ({ type: c.type, enabled: c.enabled })),
    });

    const email = getEmailForConsent();
    const hasSubscribedForNews = consents?.some(
      (c) => c.type === CONSENT_TYPES.PROJECT_NEWS
    );
    const hasSubscribedForNewCommonplaces = consents?.some(
      (c) => c.type === CONSENT_TYPES.NEW_COMMONPLACES
    );
    if (hasSubscribedForNews) {
      trackEvent(MixpanelEventTypes.NEWS_SUBSCRIPTION, {
        path: asPath,
        email,
      });
    }
    if (hasSubscribedForNewCommonplaces) {
      trackEvent(MixpanelEventTypes.NEW_COMMONPLACES_SUBSCRIPTION, {
        path: asPath,
        email,
      });
    }

    try {
      const response = await saveConsentsService({
        consents,
        email,
        lang: locale,
        project,
        apiToken: apiToken,
      });

      if (!response.ok) {
        throw new Error('Could not save selected consents.');
      }
    } catch (error) {
      captureException(
        `Error in saveConsents @ PreferencesPage.tsx : ${error}`
      );
    }
  };

  return (
    <EngagementPagesTemplate nextButtonAction={saveConsents}>
      <Wrapper>
        <Heading heading={heading} />
        <IntroDescription>{description}</IntroDescription>
        <ConsentsContainer
          consentsGroup={defaultConsents}
          onConsentsToggle={toggleConsents}
        />
        {customConsents && (customConsents?.consents?.length ?? 0) > 0 && (
          <ConsentsContainer
            consentsGroup={customConsents}
            onConsentsToggle={toggleConsents}
          />
        )}
      </Wrapper>
      <EditModeButton />
    </EngagementPagesTemplate>
  );
};
