import * as React from 'react';
import styled, { css } from 'styled-components';
import { readableColor } from 'polished';
import { ShareCommentButton } from 'Atoms/ShareCommentButton';
import { AgreeButton } from 'Atoms/AgreeButton';

const menuStyling = css`
  padding: 0.5rem 1rem;
  margin: -0.5rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  background: transparent;
  border-radius: 0.125rem;
  border: none;

  * + * {
    margin: 0;
    margin-left: 0.5rem;
  }

  ${({ theme }) => css`
    color: ${theme.colors.grey[800]};

    &:hover {
      background: ${theme.colors.grey[100]};
      border: none;
    }
  `}
`;

const getSentimentColor = ({ colorMappings }, sentiment: number) =>
  [
    colorMappings.sentimentNegative,
    colorMappings.sentimentMostlyNegative,
    colorMappings.sentimentNeutral,
    colorMappings.sentimentMostlyPositive,
    colorMappings.sentimentPositive,
  ][sentiment - 1];

export const CommentCard = styled.div<{
  sentiment?: number;
  color?: string;
  card?: boolean;
}>`
  ${({ theme }) => css`
    background: ${theme.colorMappings.white};
  `}

  ${({ card }) =>
    card &&
    css`
      margin: 0 auto 1rem;
      max-width: 56rem;
      border-radius: 0.1875rem;
    `}

  ${({ sentiment, color, theme, card }) =>
    (sentiment || color) &&
    card &&
    css`
      border-left: 0.375rem solid
        ${sentiment ? getSentimentColor(theme, sentiment) : color};
    `}

  ${({ sentiment, color, theme, card }) =>
    (sentiment || color) &&
    !card &&
    css`
      /* when showning the selected contribution in the map slide panel */
      border-top: 0.375rem solid
        ${sentiment ? getSentimentColor(theme, sentiment) : color};
      display: flex;
      flex-direction: column;
      min-height: 100%;
    `}
`;

export const SurveyBadge = styled.div`
  background: ${({ theme }) => theme.colorMappings.surveyBadgePill};
  color: ${({ theme }) => readableColor(theme.colorMappings.surveyBadgePill)};
  display: inline-block;
  padding: 0.5rem 0.75rem;
  border-radius: 0.1875rem;
  font-weight: bold;
  margin: -0.75rem 1rem -0.75rem 0;
`;

export const PivotContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const Section = styled.div`
  padding: 1rem;

  ${({ theme }) => css`
    & + & {
      border-top: 0.0625rem solid ${theme.colorMappings.separators};
    }
  `}
`;

export const SectionRow = styled(Section)<{ stickToBottom?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  ${({ stickToBottom }) =>
    stickToBottom &&
    css`
      margin-top: auto;
    `}
`;

export const SectionColumn = styled.div<{ collapse?: boolean }>`
  gap: 1rem;
  display: flex;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
  }

  ${({ theme, collapse }) =>
    collapse &&
    css`
      ${theme.breakpoints.down('xs')} {
        flex-direction: column;
        align-items: baseline;
      }
    `};
`;

export const MenuButton = styled.a`
  ${menuStyling}
`;

export const MenuShareButton = styled(ShareCommentButton)`
  ${menuStyling}
`;

export const MenuAgreeButton = styled(({ contrast, ...props }) => (
  <AgreeButton {...props} />
))`
  ${menuStyling}
  margin: 0;
  ${({ theme, contrast }) =>
    contrast
      ? css`
          color: ${theme.colors.grey[400]};

          path {
            fill: ${theme.colors.grey[400]};
          }
        `
      : css`
          background: ${theme.colors.green.A700};
          color: ${theme.palette.common.white};

          &:hover {
            background: ${theme.colors.green[600]};
            color: ${theme.palette.common.white};
          }

          path {
            fill: ${theme.palette.common.white};
          }
        `}
`;

export const SentimentDot = styled.span<{ sentiment?: number; color?: string }>`
  vertical-align: middle;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  flex: none;
  margin-right: 0.5rem;
  ${({ sentiment, color, theme }) => css`
    background: ${sentiment ? getSentimentColor(theme, sentiment) : color};
  `}
`;

export const ReadMoreText = styled.p<{ isOpen: boolean }>`
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors.green[500]};
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-left: 0.5rem;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg)' : 'rotate(270deg)')};
    color: ${({ theme }) => theme.colors.green[500]};
    width: 0.5rem;
    height: 0.5rem;
  }
`;

export const TooltipContent = styled.div`
  text-align: center;
`;
