import * as React from 'react';
import styled, { css } from 'styled-components';
import { darken } from 'polished/lib';
import { LoadingButton } from 'Atoms';
import { Button as ButtonBase } from '../Button';

export const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 6rem);
  place-content: center;
  margin-top: 3rem;
`;

export const Button = styled(({ inverse, ...props }) => (
  <LoadingButton {...props} />
))`
  border-radius: 0.3125rem;
`;
export const CancelButton = styled(({ inverse, ...props }) => (
  <ButtonBase {...props} />
))`
  border-radius: 0.3125rem;

  ${({ inverse, theme }) =>
    inverse &&
    css`
      border: none;
      background-color: ${theme.colors.white[500]};

      &:hover {
        background-color: ${theme.colors.white[500]};
        color: ${darken(
          0.1,
          theme.colorMappings.primaryButtonBackground
        )} !important;
        border: none;
      }
    `}
`;

export const QuestionWrapper = styled.div<{ isShowingPinLocation: boolean }>`
  margin: 1.5rem 2rem 4rem;
  padding: 0.3125rem;
  ${({ isShowingPinLocation }) => css`
    ${isShowingPinLocation ? '&:nth-of-type(3)' : '&:first-of-type'} {
      margin-top: 0px;
    }
  `}

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const FormButtonsContainer = styled(({ loading, ...props }) => (
  <div {...props} />
))`
  width: 100%;
  padding: 0 2rem;
  margin: 4rem 0 2rem 0;
  margin-top: auto; /* so that it stays at the bottom when only 1-2 questions are present */

  @media screen and (min-width: 769px) and (max-width: 1366px) {
    /* Styles specific to viewport width between 769px and 1366px (Tablets) */
    margin-bottom: 2rem;
  }

  ${({ loading, theme }) => `
    ${loading && theme.breakpoints.down('xs')} {
      margin: 2rem 0 1rem 0;
    }
  `};

  & > button {
    &:first-of-type {
      color: ${({ theme }) => theme.colorMappings.primaryButtonBackground};
    }
  }
`;

export const PanelIcon = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 1rem;
`;

export const PanelTitle = styled.span`
  font-size: 1.625rem;
  font-weight: 900;
  margin-left: 1rem;
`;

export const PanelHeader = styled.div`
  height: 3.75rem;
  align-items: center;
  padding: 0.75rem 0;
`;

export const PinAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.875rem;
    margin: 0 2rem;
    & > p {
      width: 90%;
    }
  }
`;
