import * as React from 'react';
import { HtmlDescription } from 'Molecules';
import { InfoPanelContainer } from './InfoPanel.styles';
import { ConfirmEmailInfoPanelProps } from '../types';

const replacePlaceholderWithEmail = (message: string, email: string): string =>
  message.replace('{{email}}', email);

/** @deprecated on CF 3.0 */
export const InfoPanel: React.FC<ConfirmEmailInfoPanelProps> = ({
  message,
  email,
}) => {
  return (
    <InfoPanelContainer>
      <HtmlDescription>
        {replacePlaceholderWithEmail(message, email)}
      </HtmlDescription>
    </InfoPanelContainer>
  );
};
