import styled, { css } from 'styled-components';
import { Image } from 'Client/pages/proposals';

export const Header = styled.header<{
  background: Image;
  isRevealing: boolean;
}>`
  ${({ theme, background, isRevealing }) => css`
    display: flex;
    justify-content: center;
    width: 100%;
    background-size: cover;
    background-image: url(${background ? background.src : ''});
    height: 10rem;
    ${theme.breakpoints.down('xs')} {
      height: ${isRevealing ? '8.75rem' : '7rem'};
      ${isRevealing && 'border-radius: 1.25rem 1.25rem 0 0;'}
      ${isRevealing && `margin-top: '7rem';`}
      transition: height 0.4s, margin-top 0.2s;
    }
  `};
`;

export const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.white[500]};
  padding: 1rem 1.6875rem;
  padding-bottom: 10rem;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      width: 100%;
      padding-top: 2rem !important;
    }
  `}
`;

export const Wrapper = styled.div<{
  isOpen: boolean;
  isEmbed: boolean;
  errorBannerHeight: number;
  stageBannerHeight: number;
}>`
  ${({ theme, isOpen, isEmbed, errorBannerHeight, stageBannerHeight }) => css`
    width: 23.5rem;
    box-shadow: 0 0.75rem 0.75rem ${theme.colorMappings.shadow};
    ${theme.direction === 'rtl' ? 'right: 0;' : 'left: 0;'}
    z-index: 2;
    position: absolute;
    overflow: visible;
    height: calc(
      100vh -
        ${isEmbed
          ? '0px'
          : `${
              Number(theme.proposals.masthead.height.replace('rem', '')) +
              errorBannerHeight +
              stageBannerHeight
            }rem`}
    );
    display: flex;
    flex-direction: column;
    transition: left 0.2s ease-out;

    ${!isOpen &&
    css`
      ${theme.direction === 'rtl' ? 'right' : 'left'}: -23.5rem;

      ${theme.breakpoints.down('md')} {
        ${theme.direction === 'rtl' ? 'right' : 'left'}: -100%;
        box-shadow: none;
      }
    `};

    // For iPads/Tablets
    @media only screen and (min-width: 768px) and (max-width: 1366px) {
      position: fixed;
      width: 100%;
      height: 80vh;
      bottom: 0;
      z-index: 100; /* to be above the search bar */
    }

    // For iPhones/Smartphones
    @media only screen and (min-width: 350px) and (max-width: 750px) {
      position: fixed;
      width: 100%;
      height: 80vh;
      bottom: 0;
      z-index: 100; /* to be above the search bar */
    }
  `}
`;
