import * as React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { fromLonLat } from 'ol/proj';
import Draw from 'ol/interaction/Draw';
import { Separator } from 'Atoms';
import { ProposalPageCf3 } from 'Client/pages/proposals/ProposalPageCf3';
import {
  MixpanelEventTypes,
  useAnalytics,
} from 'Client/utils/hooks/useAnalytics';
import { useMap, useProposalContext } from 'Client/utils/hooks/useMapHooks';
import { ContributionType } from 'Shared/types/contribution';
// import type { MapBoxApiResponse } from 'Shared/types';
// import { useProject } from 'Client/utils/hooks/useProject';
import { SlidePanel } from '../MapSlidePanel';
import {
  LoaderContainer,
  PanelHeader,
  PanelIcon,
  PanelTitle,
} from './ContributionFlowPanelCf3.styles';

export const ContributionFlowPanelCf3 = (props): JSX.Element => {
  // const {
  //   dispatch,
  //   state: { proposal, contributionFlow, xyz, draftContributionLayer },
  // } = useMap();
  // const project = useProject();
  // const router = useRouter();
  // const { data: session } = useSession();
  const router = useRouter();
  const { t } = useTranslation();
  const { trackEvent } = useAnalytics();
  const {
    dispatch,
    state: { contributionFlow, xyz },
  } = useMap();
  const [{ answers }] = useProposalContext();
  // const project = useProject();
  // const canShowPinLocationAddress = project?.features?.geocoding || false;
  const [loading, setLoading] = React.useState(true);
  // const [coordAddress, setCoordAddress] = React.useState<string>('');

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false); // TODO: call setLoading(false) when all props really finished loading
    }, 5000);
  }, []);

  // const response: Promise<MapBoxApiResponse> = React.useMemo(async () => {
  //   try {
  //     const coords = contributionFlow?.geometry?.coordinates;

  //     if (!canShowPinLocationAddress) {
  //       return;
  //     }

  //     const input = `${coords[0]},${coords[1]}`;
  //     const otherParameters = {};

  //     const response = await fetch(`/api/map/fetchGeocodingCoords`, {
  //       method: 'POST',
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         input,
  //         otherParameters,
  //       }),
  //     });

  //     return response.json();
  //   } catch (err) {
  //     console.error(
  //       `Error in mapBox api call @ ContributionFlowPanelCf3/index.tsx: ${err}`
  //     );
  //   }
  // }, [contributionFlow?.geometry]);

  // const getPinLocation = React.useCallback(() => {
  //   response.then((res) =>
  //     setCoordAddress(res?.suggestions?.[0]?.place_formatted)
  //   );
  // }, [contributionFlow?.geometry]);

  React.useEffect(() => {
    if (
      contributionFlow &&
      contributionFlow.type === ContributionType.COMMENT &&
      contributionFlow.geometry &&
      xyz
    ) {
      try {
        // This is the code that is moving the map centroid when you place or replace your pin on contribution flow:
        const { coordinates } = contributionFlow.geometry;
        const coordsMapped = fromLonLat(coordinates);

        xyz.map.getView().animate({ center: coordsMapped });

        // if (canShowPinLocationAddress) {
        //   getPinLocation();
        // }
      } catch (err) {
        console.error(
          `Error in useEffect @ ContributionFlowPanelCf3/index.tsx: ${err}`
        );
      }
    }
  }, [xyz, contributionFlow]);

  const closePanel = async () => {
    try {
      if (contributionFlow && contributionFlow.contributionId) {
        const sentiment = answers[props.sentimentQuestionId] || null;
        console.log('sentiment: ', sentiment);
        const sentimentAnswered = Number.isInteger(sentiment);
        const numOfAnswers =
          Object.keys(contributionFlow?.data || {}).length +
          (sentimentAnswered ? 1 : 0);
        trackEvent(MixpanelEventTypes.COMMENT_CANCELLED, {
          path: router.asPath,
          contributionId: contributionFlow.contributionId,
          numOfAnswers,
        });

        if (xyz?.mapview?.interaction?.draw) {
          xyz.map.getInteractions().forEach((interaction) => {
            if (interaction instanceof Draw) {
              interaction.setActive(false);
            }
          });

          xyz.mapview.node.style.cursor = 'default';
        }

        xyz.layers.list.Contributions.reload();
      }

      dispatch({ type: 'CLEAR_LEFT_PANEL' });
      dispatch({ type: 'CLEAR_CONTRIBUTION_FLOW' });
      dispatch({
        type: 'SET_CONTRIBUTION_FLOW_STARTED',
        payload: false,
      });
    } catch (error) {
      console.error('closePanel cf3 error: ', error);
    }
  };

  return (
    <SlidePanel onClose={closePanel}>
      <PanelHeader>
        <PanelIcon
          width="20"
          height="20"
          src="/static/android-chrome-filled-192x192.png"
        />
        <PanelTitle>{t('Have your say')}</PanelTitle>
        <Separator height={'0.125rem'} />
      </PanelHeader>
      {/* {isHandlingSubmit && <LoadingScreen withLoadingRing={true} />} */}
      {loading ? (
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
      ) : (
        <ProposalPageCf3 {...props} />
      )}
    </SlidePanel>
  );
};
