import styled, { css } from 'styled-components';

export const SearchInputContainer = styled.div<{
  isOpen: boolean;
  isMobile: boolean;
  isEmbed: boolean;
  bannerHeight: number;
  cf3: boolean;
  contributionFlowStarted: boolean;
}>`
  ${({
    isMobile,
    isOpen,
    isEmbed,
    theme,
    bannerHeight,
    cf3,
    contributionFlowStarted,
  }) => css`
    background-color: hsl(0, 0%, 100%);
    display: flex;
    flex-direction: ${theme.direction === 'rtl' ? 'row-reverse' : 'row'};
    align-items: center;
    height: 3.125rem;
    border-radius: 0.3125rem;
    border: 1px solid black;
    position: absolute;
    top: ${isEmbed
      ? '0px'
      : `${
          Number(theme.proposals.masthead.height.replace('rem', '')) +
          1.5 +
          bannerHeight
        }rem`};
    z-index: 100;
    transition: 0.2s ease;
    gap: 5px;
    ${theme.direction === 'rtl' ? 'margin-right' : 'margin-left'}: ${isOpen
      ? isMobile
        ? '100%' // In order to have a better animation when closing on mobile
        : cf3 && contributionFlowStarted
        ? '39.125rem'
        : '28.125rem'
      : '5.5rem'} !important;
    width: ${isMobile ? '50%' : isOpen ? '32%' : '60%'};
    ${isMobile && isOpen && `position: unset;`}
    ${theme.breakpoints.down('sm')} {
      width: ${isOpen ? '40%' : '70%'};
    }
  `}
`;

export const SearchInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 100%;
`;
export const MapLeftPanel = styled.div<{
  isEmbed: boolean;
  errorBannerHeight: number;
  stageBannerHeight: number;
  cf3: boolean;
}>`
  ${({ theme, isEmbed, errorBannerHeight, stageBannerHeight, cf3 }) => css`
    width: ${cf3 ? '37.5rem' : '25.9375rem'};
    height: calc(
      100vh -
        ${isEmbed
          ? '0px'
          : `${
              Number(theme.proposals.masthead.height.replace('rem', '')) +
              errorBannerHeight +
              stageBannerHeight
            }rem`}
    );
    overflow: hidden;
    position: relative;

    // For iPhones/Smartphones
    @media only screen and (min-width: 350px) and (max-width: 750px) {
      width: 100%;
      height: calc(
        80vh -
          ${isEmbed
            ? '0px'
            : `${
                Number(theme.proposals.masthead.height.replace('rem', '')) +
                errorBannerHeight +
                stageBannerHeight
              }rem`}
      );
      overflow: auto;
      position: absolute;
      bottom: 0;
    }

    // For iPads/Tablets
    @media only screen and (min-width: 768px) and (max-width: 1366px) {
      width: 100%;
      height: 50vh;
      overflow: auto;
      position: absolute;
      bottom: 0;
    }

    /* unset map4 styles */
    ul,
    ol {
      padding: revert;
    }
    li {
      list-style-type: inherit;
    }
    /* ------- */
  `}
`;
export const SearchInputIcon = styled.label<{ isMobile: boolean }>`
  ${({ isMobile, theme }) => css`
    ${theme.direction === 'rtl' &&
    `
      ${isMobile ? `right: 0.8rem;` : `left: 0.8rem;`}
    `}

    ${theme.direction !== 'rtl' &&
    `
       ${
         isMobile
           ? `
       left: 0;
       padding-left: 0.9375rem;
       `
           : `
       right: 4.0625rem;
       padding-right: 0.9375rem;
       `
       }
    `}
    z-index: 10;
    background-color: hsl(0, 0%, 100%);
    height: 1.25rem;
    position: absolute;
  `}
`;

export const FilterButtonSection = styled.div<{ isMobile: boolean }>`
  ${({ isMobile, theme }) => css`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    gap: 0.625rem;
    position: absolute;
    ${theme.direction === 'rtl' &&
    `
    ${
      isMobile
        ? `
        left: 1rem;
        `
        : `left: 3rem;`
    }`}
    ${theme.direction !== 'rtl' &&
    ` ${isMobile ? 'right: 1rem;' : 'right: 0.625rem;'}`}
  `}
`;
